import { useMemo } from 'react';
import * as React from 'react';
import { CacheProvider } from '@emotion/react';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { QueryClientProvider } from '@tanstack/react-query';
import { ConfirmOptions, ConfirmProvider } from 'material-ui-confirm';

import { PlootoTheme_DoNotUse_ThisIsOnlyForSettingUpTheMaterialTheme_CodeReviewerPleaseEnforceThis as theme } from '@/config/theme';
import { I18nProvider, userPreferredLocale } from '@/features/i18n';
import { useSyncKoObservable } from '@/hooks/useSyncKoObservable';
import { Instance as CompanyService } from '@/legacy/services/company';
import { Instance as UserService } from '@/legacy/services/user';
import CompanyContext, { CompanyContextState } from '@/providers/CompanyContext';
import { emotionCache, queryClient } from '@/providers/PlootoPlatformProvider.caches';
import { ThemedSnackbarProvider } from '@/providers/SnackbarProvider';
import UserContext, { UserContextState } from '@/providers/UserContext';

type PlootoPlatformProviderProps = {
  children: React.ReactNode;
};

/** These correspond to our chosen date format in XDate (e.g. 09 Sep 2025). */
const DateFormatsDateFns = {
  keyboardDate: 'dd MMM yyyy',
  keyboardDateTime: 'dd MMM yyyy p',
  keyboardDateTime12h: 'dd MMM yyyy hh:mm aa',
  keyboardDateTime24h: 'dd MMM yyyy HH:mm',
};

/**
 * Default options for ConfirmProvider to disable implicit dialog closing, which is totally broken
 * due to a bad decision by the library author.
 *
 * See: https://github.com/jonatanklosko/material-ui-confirm/issues/25
 */
const DefaultConfirmOptions: ConfirmOptions = {
  allowClose: false,
};

/**
 * Provides the Plooto theme and query client to the application.
 * @param children The children to render.
 */
const PlootoPlatformProvider: React.FunctionComponent<PlootoPlatformProviderProps> = ({
  children,
}: PlootoPlatformProviderProps) => {
  const accountingFirmCompanyId = useSyncKoObservable(CompanyService.AccountingFirmCompanyId);
  const companyCountry = useSyncKoObservable(CompanyService.CompanyCountry);
  const companyAccountingFirmClientId =
    useSyncKoObservable(CompanyService.companyAccountingFirmClientId) ?? null;
  const companyId = useSyncKoObservable(CompanyService.CompanyId);
  const profile = useSyncKoObservable(CompanyService.profile);
  const isOnFreeTrial = useSyncKoObservable(profile?.isOnFreeTrial);
  const userId = useSyncKoObservable(UserService.UserId);
  const email = useSyncKoObservable(UserService.DisplayEmail);
  const membership = useSyncKoObservable(CompanyService.membership);
  const permissions = useSyncKoObservable(membership?.permissions);
  const subscriptionFeatures = useSyncKoObservable(profile?.subscriptionFeatures);
  const isDualControlsEnabled = useSyncKoObservable(subscriptionFeatures?.isDualControlsEnabled);

  const companyContext = useMemo<CompanyContextState>(
    () => ({
      accountingFirmCompanyId,
      companyCountry,
      companyAccountingFirmClientId,
      companyId,
      isOnFreeTrial,
      permissions,
      isDualControlsEnabled,
    }),
    [
      accountingFirmCompanyId,
      companyAccountingFirmClientId,
      companyCountry,
      companyId,
      isOnFreeTrial,
      permissions,
      isDualControlsEnabled,
    ]
  );

  const userContext = useMemo<UserContextState>(
    () => ({
      userId,
      email,
    }),
    [userId, email]
  );

  return (
    <CompanyContext.Provider value={companyContext}>
      <UserContext.Provider value={userContext}>
        <QueryClientProvider client={queryClient}>
          <I18nProvider locale={userPreferredLocale}>
            <LocalizationProvider dateAdapter={AdapterDateFns} dateFormats={DateFormatsDateFns}>
              <CacheProvider value={emotionCache}>
                <ThemeProvider theme={theme}>
                  <ConfirmProvider defaultOptions={DefaultConfirmOptions}>
                    <ThemedSnackbarProvider>{children}</ThemedSnackbarProvider>
                  </ConfirmProvider>
                </ThemeProvider>
              </CacheProvider>
            </LocalizationProvider>
          </I18nProvider>
        </QueryClientProvider>
      </UserContext.Provider>
    </CompanyContext.Provider>
  );
};

export { DateFormatsDateFns, DefaultConfirmOptions };
export default PlootoPlatformProvider;
