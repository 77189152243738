// These strings are references to an external system.
/* eslint-disable @cspell/spellchecker */

/**
 * Feature flags defined in Split.io.
 */
enum FeatureFlag {
  /**
   * A test Split.io feature which can be enabled on a company level.
   */
  UNIT_TEST_ONLY_COMPANY_TEST_FLAG = 'TEST_SPLITIO_COMPANY',

  /**
   * A test Split.io feature which can be enabled on a user level.
   */
  UNIT_TEST_ONLY_USER_TEST_FLAG = 'TEST_SPLITIO_USER',

  /**
   * New design for Search & Filter Tables of Import Payables page (PLP-27539)
   */
  USE_NEW_SEARCH_FILTER_IMPORT_PAYABLES_TABLE = 'USE_NEW_SEARCH_FILTER_IMPORT_PAYABLES_TABLE',

  /**
   * New design for Search & Filter tables for receivables with accounting software connectio table
   * (PLP-27551)
   */
  SPR9_2023_USE_NEW_IMPORT_RECEIVABLES_TABLE = 'SPR9_2023_USE_NEW_IMPORT_RECEIVABLES_TABLE',

  /** Allow same-day payments between 3 PM and 5 PM */
  SPR13_23_PROCESSINGWINDOW = 'SPR13_23_PROCESSINGWINDOW',

  /**
   * Allow clients to use their credit or debit cards to pay vendors.
   *
   * @see {@link https://www.notion.so/plooto/Pay-by-Card-8abf2c43512d4825a6deda2c451461e5 | Project workspace}
   * @see {@link https://www.notion.so/plooto/xPager-Implementing-a-Pay-by-Card-Option-for-Nodes-27c5790d57ad4743b4467158c78e0f22 | X-Pager}
   */
  SPR19_2023_PAY_BY_CARD = 'SPR19_2023_PAY_BY_CARD',

  /**
   * Enables the Referrals experience.
   *
   * @see {@link https://www.notion.so/plooto/Referrals-47bfb5da73ed44909a9456200a60e267 | Project}
   * @see {@link https://www.notion.so/plooto/be57e6b797b0475c9feea67c73da9920?v=20d9bb5b003c474689295b4b0b8baaa5 | xPager}
   */
  SPR23_2023_REFERRALS = 'SPR23_2023_REFERRALS',

  /**
   * New instant plan upgrades UI.
   *
   *  @see {@link https://app.clickup.com/t/8576364/PLP-33512 | xPager}
   *  @see {@link https://www.notion.so/plooto/Pro-Plan-Enhancement-Billing-UI-and-Instant-Plan-Upgrades-107e015329de482c9024d4083c88baed | Project}
   */
  SPR2_2024_INSTANT_PLAN_UPGRADES = 'SPR2_2024_INSTANT_PLAN_UPGRADES',

  /**
   * New mass import UI.
   *
   * @see {@link https://app.clickup.com/t/8576364/PLP-33463 | Ticket}
   */
  SPR4_24_MASSIMPORT_NEW = 'SPR4_24_MASSIMPORT_NEW',

  /**
   * New network experiment.
   *
   * @see {@link https://app.clickup.com/t/8576364/PLP-34750 | Ticket}
   */
  SPR6_2024_NETWORK_EXPERIMENT1_1 = 'SPR6_2024_NETWORK_EXPERIMENT1_1',

  /**
   * New company selector.
   *
   *  @see {@link https://www.notion.so/plooto/Client-Management-Dashboard-Stuff-Parent-Nodes-df038c2a40e1443e8e0780c191490d8a | Project}
   */
  SPR3_2024_COMPANY_SELECTOR_V2 = 'SPR3_2024_COMPANY_SELECTOR_V2',

  /**
   * Show Audit trail logs for receivables
   *
   * @see {@link https://app.clickup.com/t/8576364/PLP-33967 | Ticket}
   */
  SPR3_2024_RECEIVABLE_AUDIT_LOG = 'SPR3_2024_RECEIVABLE_AUDIT_LOG',

  /**
   * Onboarding Experiment 3 (X3): Onboarding Wizard redesign.
   * @see {@link https://app.clickup.com/t/8576364/PLP-34410 | Epic}
   */
  SPR5_2024_ONBOARDING_WIZARD_REDESIGN = 'SPR5_2024_ONBOARDING_WIZARD_REDESIGN',

  /**
   * New onboarding Persona UI
   * @see {@link https://app.clickup.com/t/8576364/PLP-34347 | ticket}
   */
  SPR5_24_ONBOARDING_PERSONA = 'SPR5_24_ONBOARDING_PERSONA',

  /**
   * New international bank details UI
   * @see {@link https://app.clickup.com/t/8576364/PLP-34193 | Epic}
   */
  SPR5_2024_INTERNATIONAL_BANK_DETAILS = 'SPR5_2024_INTERNATIONAL_BANK_DETAILS',

  /**
   * Plooto Network Improvement 1.1
   * @see {@link https://app.clickup.com/t/8576364/PLP-34750 | Epic}
   */
  SPR7_2024_NETWORK_IMPROVEMENT1_1 = 'SPR7_2024_NETWORK_IMPROVEMENT1_1',

  /**
   * Enhanced UX for Fund Transfer
   * @see {@link https://app.clickup.com/t/8576364/PLP-33460 | Epic}
   */
  SPR10_24_TRANSFER_FUNDS_ENHANCEMENT = 'SPR10_24_TRANSFER_FUNDS_ENHANCEMENT',
}

export default FeatureFlag;
