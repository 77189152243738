import * as ko from 'knockout';

import { Instance as CompanyService } from '@/legacy/services/company';
import { Instance as UserService } from '@/legacy/services/user';
import { getNameFromCode, PlanNames } from '@/legacy/utils/plootoPlan';

enum SingleSignOnFeatureState {
  /** Not available at all. */
  NotAvailable,
  /** Currently enabled, configurable. */
  Enabled,
  /** Not currently enabled, but can be enabled. */
  Available,
  /** Not currently available, but can be accessed via plan upgrade. */
  AvailableForUpgrade,
}

/** Plans that SSO are available to as an upgrade. */
const UpgradeForPlans: ReadonlySet<string> = new Set([
  getNameFromCode(PlanNames.Go),
  getNameFromCode(PlanNames.Grow),
  getNameFromCode(PlanNames['Grow Unlimited']),
]);

/**
 * Plans that SSO are available to innately.
 *
 * TODO(PLP-35965): Remove this after BE updated with isSingleSignOnEnabled.
 */
const AvailableForPlans: ReadonlySet<string> = new Set([getNameFromCode(PlanNames.Pro)]);

class SingleSignOnFeatureToggleService {
  private static IsSingleSignOnAdminUser = ko.pureComputed<boolean>(
    () => UserService.profile()?.isSsoAdminUser() ?? false
  );

  private static IsSingleSignOnFeatureEnabled: KnockoutObservable<boolean> = ko.observable(false);

  public static FeatureState = ko.pureComputed(() => {
    if (this.IsSingleSignOnFeatureEnabled() && this.IsSingleSignOnAdminUser()) {
      return SingleSignOnFeatureState.Enabled;
    }
    const planName = CompanyService.profile()?.subscriptionFeatures()?.subscriptionName();
    const isSingleSignOnEnabled = CompanyService.profile()
      ?.subscriptionFeatures()
      ?.isSingleSignOnEnabled?.();
    if (isSingleSignOnEnabled ?? AvailableForPlans.has(planName)) {
      return SingleSignOnFeatureState.Available;
    }
    if (UpgradeForPlans.has(planName)) {
      return SingleSignOnFeatureState.AvailableForUpgrade;
    }
    return SingleSignOnFeatureState.NotAvailable;
  });

  public static IsSingleSignOnEnabled: KnockoutObservable<boolean> = ko.pureComputed<boolean>(
    () => SingleSignOnFeatureToggleService.FeatureState() !== SingleSignOnFeatureState.NotAvailable
  );

  private static updateFeatureStatus() {
    const ssoAdminCompanyId = UserService.profile()?.ssoAdminCompanyId();

    SingleSignOnFeatureToggleService.IsSingleSignOnFeatureEnabled(!!ssoAdminCompanyId);
  }

  public static bind() {
    CompanyService.CompanyId.subscribe(SingleSignOnFeatureToggleService.updateFeatureStatus);
    UserService.profile.subscribe(SingleSignOnFeatureToggleService.updateFeatureStatus);
  }
}

export { SingleSignOnFeatureState };
export default SingleSignOnFeatureToggleService;
