enum TransferFundsEventType {
  TransferFundsFormPresented = 'transferFunds:form:presented',
  TransferFundsFillFormBack = 'transferFunds:fillForm:back',
  TransferFundsFillContinue = 'transferFunds:fillForm:continue',
  TransferFundsReviewBack = 'transferFunds:review:back',
  TransferFundsReviewConfirm = 'transferFunds:review:confirm',
}

enum TransferFundsEventName {
  TransferFundsFormPresented = 'Transfer Funds - Form Presented',
  TransferFundsFillBack = 'Transfer Funds - Fill - Back',
  TransferFundsFillContinue = 'Transfer Funds - Fill - Continue',
  TransferFundsReviewBack = 'Transfer Funds - Review - Back',
  TransferFundsReviewConfirm = 'Transfer Funds - Review - Confirm',
}

enum TransferFundsFormEventButtonName {
  Back = 'Back',
  Next = 'Next',
}

enum TransferFundsReviewEventButtonName {
  Back = 'Back',
  Confirm = 'Confirm',
}

enum TransferFundsContext {
  TransferFunds = 'Transfer Funds',
  Confirm = 'Confirm Transfer of Funds',
}

enum FormFieldNameConstants {
  SubmissionStatus = 'submissionStatus',
}

enum FormFieldValueConstants {
  Completed = 'completed',
  Incomplete = 'incomplete',
}

enum AccountTypeFieldValue {
  Bank = 'bank',
  PlootoInstantCad = 'plootoInstantCAD',
  PlootoInstantUsd = 'plootoInstantUSD',
}

const TRANSFER_FUNDS_FORM_NAME = 'Transfer Funds';

export {
  AccountTypeFieldValue,
  FormFieldNameConstants,
  FormFieldValueConstants,
  TRANSFER_FUNDS_FORM_NAME,
  TransferFundsEventType,
  TransferFundsEventName,
  TransferFundsFormEventButtonName,
  TransferFundsReviewEventButtonName,
  TransferFundsContext,
};
