const PlanCodes = {
  PlootoPlanAServiceSubscription: 'Legacy',
  PlootoPlanBServiceSubscription: 'Legacy',
  PlootoPlanCServiceSubscription: 'Legacy',
  PlootoPlanDServiceSubscription: 'Grow',
  PlootoPlanEServiceSubscription: 'Grow Unlimited',
  PlootoPlanFServiceSubscription: 'Go',
  PlootoPlanProServiceSubscription: 'Pro',
  CustomSubscriptionPlan: 'Deloitte Client',
} as const;

type PlanCode = keyof typeof PlanCodes;

const PlanNames = {
  Go: 'PlootoPlanFServiceSubscription',
  Grow: 'PlootoPlanDServiceSubscription',
  'Grow Unlimited': 'PlootoPlanEServiceSubscription',
  Legacy: 'PlootoPlanCServiceSubscription',
  Pro: 'PlootoPlanProServiceSubscription',
  'Deloitte Client': 'CustomSubscriptionPlan',
} as const;

type PlanName = keyof typeof PlanNames;

const getNameFromCode = (code: PlanCode): PlanName => PlanCodes[code];

const getIsLegacy = (code: PlanCode): boolean => getNameFromCode(code) === 'Legacy';

export default PlanCodes;
export { getIsLegacy, getNameFromCode, PlanNames };
export type { PlanCode, PlanName };
