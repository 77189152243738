import * as dialog from 'plugins/dialog';

import BaseModal from '@/legacy/modals/BaseModal';

declareModules({
  'modals/styled/titleMessage.html': () => import('@/legacy/modals/styled/titleMessage.html'),
});

interface ViewModelParams {
  title: string;

  message?: string;
  messageHtml?: string;

  continueText?: string;

  closeCallback?: () => void;

  svgIcon?: string;
  svgIconSymbol?: string;
  svgIconSymbolLarge?: string;
}

class StyledModal extends BaseModal {
  view = 'modals/styled/titleMessage';

  public params: ViewModelParams;

  public continueText: string;

  public closeCallback: () => void;

  constructor(params: ViewModelParams) {
    super();
    this.params = params;
    this.continueText = params.continueText ? params.continueText : 'Continue';
    this.closeCallback = params.closeCallback;
  }

  public close(): void {
    dialog.close(this);
    this.closeCallback?.();
  }

  public static show(params: ViewModelParams): JQueryPromise<any> {
    return dialog.show(new StyledModal(params));
  }
}

export default StyledModal;
export { ViewModelParams };
