import { defineMessage, MessageDescriptor } from 'react-intl';

import CountryCode from '@/features/location/types/CountryCode';

import type { PlanCode } from './codes';

const unlimitedApprovers = defineMessage({
  defaultMessage: 'Unlimited Approvers',
});

const unlimitedAccountantsAndBookepers = defineMessage({
  defaultMessage: 'Unlimited Accountants and Bookkeepers',
});

const advancedApprovalWorkflow = defineMessage({
  defaultMessage: 'Advanced approval workflow',
});

const unlimitedFreeDomesticTransactions = defineMessage({
  defaultMessage: 'Unlimited number of FREE domestic transactions',
});

const unlimitedFreeAchTransactions = defineMessage({
  defaultMessage: 'Unlimited number of FREE ACH transactions',
});

const childNodePlanFeaturesCaGrowUnlimited: ReadonlyArray<MessageDescriptor> = [
  unlimitedApprovers,
  unlimitedAccountantsAndBookepers,
  advancedApprovalWorkflow,
  unlimitedFreeDomesticTransactions,
];

const childNodePlanFeaturesUsGrowUnlimited: ReadonlyArray<MessageDescriptor> = [
  unlimitedApprovers,
  unlimitedAccountantsAndBookepers,
  advancedApprovalWorkflow,
  unlimitedFreeAchTransactions,
];

const parentNodePlanFeaturesCaGrowUnlimited: ReadonlyArray<MessageDescriptor> = [
  unlimitedApprovers,
  advancedApprovalWorkflow,
  unlimitedFreeDomesticTransactions,
];

const parentNodePlanFeaturesUsGrowUnlimited: ReadonlyArray<MessageDescriptor> = [
  unlimitedApprovers,
  advancedApprovalWorkflow,
  unlimitedFreeAchTransactions,
];

const legacyPlan: ReadonlyArray<MessageDescriptor> = [
  defineMessage({
    defaultMessage: 'Legacy Plan',
  }),
];

const proPlanCa: ReadonlyArray<MessageDescriptor> = [
  defineMessage({ defaultMessage: 'Dual control' }),
  defineMessage({ defaultMessage: 'Single sign-on (SSO)' }),
  defineMessage({ defaultMessage: 'Priority customer support' }),
  defineMessage({ defaultMessage: 'Plooto Capture (OCR)' }),
  defineMessage({ defaultMessage: 'Custom approval workflows' }),
  defineMessage({ defaultMessage: 'Unlimited accountant users' }),
  defineMessage({ defaultMessage: 'Unlimited domestic transaction volume' }),
  defineMessage({ defaultMessage: 'International payments with competitive exchange rates' }),
  defineMessage({ defaultMessage: 'Accept payments with PAD and credit card' }),
  defineMessage({ defaultMessage: 'Automatic approval routing (coming soon)' }),
];

const proPlanUs: ReadonlyArray<MessageDescriptor> = [
  defineMessage({ defaultMessage: 'Dual control' }),
  defineMessage({ defaultMessage: 'Single sign-on (SSO)' }),
  defineMessage({ defaultMessage: 'Priority customer support' }),
  defineMessage({ defaultMessage: 'Plooto Capture (OCR)' }),
  defineMessage({ defaultMessage: 'Custom approval workflows' }),
  defineMessage({ defaultMessage: 'Unlimited accountant users' }),
  defineMessage({ defaultMessage: 'Unlimited ACH transactions per month' }),
  defineMessage({ defaultMessage: 'International payments with competitive exchange rates' }),
  defineMessage({ defaultMessage: 'Accept payments with PAD and credit card' }),
  defineMessage({ defaultMessage: 'Automatic approval routing (coming soon)' }),
];

const growPlanCaChildNode: ReadonlyArray<MessageDescriptor> = [
  defineMessage({ defaultMessage: 'Plooto Capture (OCR)' }),
  defineMessage({ defaultMessage: 'Custom approval workflows' }),
  defineMessage({ defaultMessage: 'Unlimited accountant users' }),
  defineMessage({ defaultMessage: 'Unlimited domestic transaction volume' }),
  defineMessage({ defaultMessage: 'International payments with competitive exchange rates' }),
  defineMessage({ defaultMessage: 'Accept payments with PAD and credit card' }),
];

const growPlanUsChildNode: ReadonlyArray<MessageDescriptor> = [
  defineMessage({ defaultMessage: 'Plooto Capture (OCR)' }),
  defineMessage({ defaultMessage: 'Custom approval workflows' }),
  defineMessage({ defaultMessage: 'Unlimited accountant users' }),
  defineMessage({ defaultMessage: 'Unlimited ACH transactions per month' }),
  defineMessage({ defaultMessage: 'International payments with competitive exchange rates' }),
  defineMessage({ defaultMessage: 'Accept payments with PAD and credit card' }),
];

const goPlanCaChildNode: ReadonlyArray<MessageDescriptor> = [
  defineMessage({ defaultMessage: 'Plooto Capture (OCR)' }),
  defineMessage({ defaultMessage: 'Basic approval workflows with 1 approver ' }),
  defineMessage({ defaultMessage: 'Work with 1 accountant user' }),
  defineMessage({ defaultMessage: '5 domestic  transactions per month ($1 each)' }),
  defineMessage({ defaultMessage: 'International payments with competitive exchange rates' }),
  defineMessage({ defaultMessage: 'Accept payments with PAD and credit card' }),
];

const goPlanUsChildNode: ReadonlyArray<MessageDescriptor> = [
  defineMessage({ defaultMessage: 'Plooto Capture (OCR)' }),
  defineMessage({ defaultMessage: 'Basic approval workflows with 1 approver ' }),
  defineMessage({ defaultMessage: 'Work with 1 accountant user' }),
  defineMessage({ defaultMessage: '5 ACH  transactions per month ($1 each)' }),
  defineMessage({ defaultMessage: 'International payments with competitive exchange rates' }),
  defineMessage({ defaultMessage: 'Accept payments with PAD and credit card' }),
];

const deloitteClientPlan: ReadonlyArray<MessageDescriptor> = [
  defineMessage({ defaultMessage: 'Plooto Capture (OCR)' }),
  defineMessage({ defaultMessage: 'Custom approval workflows' }),
  defineMessage({ defaultMessage: 'Dual controls on user actions' }),
  defineMessage({ defaultMessage: 'Unlimited domestic transaction volume' }),
  defineMessage({ defaultMessage: 'International payments with competitive exchange rates' }),
  defineMessage({ defaultMessage: 'Accept payments with PAD and credit card' }),
];

function getGrowFeatures(country: CountryCode): ReadonlyArray<MessageDescriptor> {
  return country === 'CA' ? growPlanCaChildNode : growPlanUsChildNode;
}

function getGrowUnlimitedFeatures(
  country: CountryCode,
  isChildNode: boolean
): ReadonlyArray<MessageDescriptor> {
  if (isChildNode) {
    return country === 'CA'
      ? childNodePlanFeaturesCaGrowUnlimited
      : childNodePlanFeaturesUsGrowUnlimited;
  }

  return country === 'CA'
    ? parentNodePlanFeaturesCaGrowUnlimited
    : parentNodePlanFeaturesUsGrowUnlimited;
}

function getGoFeatures(country: CountryCode): ReadonlyArray<MessageDescriptor> {
  return country === 'CA' ? goPlanCaChildNode : goPlanUsChildNode;
}

function getProFeatures(country: CountryCode): ReadonlyArray<MessageDescriptor> {
  return country === 'CA' ? proPlanCa : proPlanUs;
}

/**
 * Returns the internationalized copy for the features included in the plan.
 * @param plan The plan code.
 * @param country The country code.
 * @param isChildNode Flag to indicate if the plan is for a child node.
 * @returns The copy for the features included in the plan.
 */
function getFeatures(
  plan: PlanCode,
  country: CountryCode,
  isChildNode: boolean
): ReadonlyArray<MessageDescriptor> {
  switch (plan) {
    case 'PlootoPlanAServiceSubscription':
    case 'PlootoPlanBServiceSubscription':
    case 'PlootoPlanCServiceSubscription':
      return legacyPlan;

    case 'PlootoPlanDServiceSubscription':
      return getGrowFeatures(country);

    case 'PlootoPlanEServiceSubscription':
      return getGrowUnlimitedFeatures(country, isChildNode);

    case 'PlootoPlanFServiceSubscription':
      return getGoFeatures(country);

    case 'PlootoPlanProServiceSubscription':
      return getProFeatures(country);

    case 'CustomSubscriptionPlan':
      return deloitteClientPlan;

    default:
      return [];
  }
}

export default getFeatures;
