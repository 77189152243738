import { memoize } from 'lodash-es';

import CurrencyCode from '@/features/financial/types/CurrencyCode';
import { userPreferredLocale } from '@/features/i18n';

enum DisplayMoneyFormat {
  /** Amount without symbol and long currency, @example "1,970.05 USD" */
  Long = 'long',
  /** Amount with symbol and no currency, @example "$1,970.05" */
  Short = 'short',
  /** Very short, omitting any unnecessary parts like cents @example "$5" */
  Marketing = 'marketing',
}

const ReCurrencyFirst = /^([A-Z]+)\s*/;

const getFormatter = memoize(
  (currency: CurrencyCode | string, format: DisplayMoneyFormat) => {
    const options: Intl.NumberFormatOptions = {
      style: currency ? 'currency' : 'decimal',
      currency: currency || undefined,
      // This is the one place that formatting money by hand is allowed.
      // eslint-disable-next-line no-restricted-syntax
      currencyDisplay: currency ? 'code' : undefined,
      currencySign: currency ? 'standard' : undefined,
      // This is the one place that formatting money by hand is allowed.
      // eslint-disable-next-line no-restricted-syntax
      minimumIntegerDigits: 1,
      // This is the one place that formatting money by hand is allowed.
      // eslint-disable-next-line no-restricted-syntax
      minimumFractionDigits: 2,
      // This is the one place that formatting money by hand is allowed.
      // eslint-disable-next-line no-restricted-syntax
      maximumFractionDigits: 2,
      useGrouping: true,
    };
    switch (format) {
      case DisplayMoneyFormat.Long:
        break;
      case DisplayMoneyFormat.Short:
        if (currency) {
          options.currencyDisplay = 'symbol';
        }
        break;
      case DisplayMoneyFormat.Marketing:
        if (currency) {
          options.currencyDisplay = 'symbol';
        }
        options.minimumFractionDigits = 0;
        break;
      default:
        throw new RangeError(`Unknown format value: ${format}`);
    }
    // This is the one place that formatting money by hand is allowed.
    // eslint-disable-next-line no-restricted-syntax
    return new Intl.NumberFormat(userPreferredLocale, options);
  },
  (currency, format) => `${currency},${format}`
);

/**
 * Parses an amount from a formatted money amount using the same character stripping scheme as
 * legacy. It coerces invalid values to 0.
 */
const parseMoney = (money: string | number) => {
  let value = money;
  if (typeof value === 'string') {
    value = Number.parseFloat(value.replaceAll(/[^\d.-]/g, ''));
  }
  // Coerce it to 0 under the same conditions as legacy.
  if (value == null || Number.isNaN(value) || !Number.isFinite(value)) {
    value = 0;
  }
  return value;
};

/** Converts a money value to a string for display. */
const formatMoney = (
  amount: number | string,
  currency: CurrencyCode | string,
  format: DisplayMoneyFormat
) => {
  let value = amount;
  // Parse it from a string using the same character stripping scheme as legacy.
  if (typeof value === 'string') {
    value = parseMoney(value);
  }
  // Coerce it to 0 under the same conditions as legacy.
  if (value == null || Number.isNaN(value) || !Number.isFinite(value)) {
    value = 0;
  }
  let formatted = getFormatter(currency, format).format(value);
  switch (format) {
    case DisplayMoneyFormat.Long: {
      // For Long, force the currency display at the end.

      const match = ReCurrencyFirst.exec(formatted);
      if (match) {
        const [currencyAndSeparator, currencyDisplay] = match;
        formatted = `${formatted.slice(currencyAndSeparator.length)} ${currencyDisplay}`;
      }
      break;
    }
    case DisplayMoneyFormat.Short:
    case DisplayMoneyFormat.Marketing: {
      // For short/marketing, force the currency display to be narrowSymbol.
      const match = ReCurrencyFirst.exec(formatted);
      if (match) {
        const [currencyAndSeparator] = match;
        formatted = formatted.slice(currencyAndSeparator.length);
      }
      break;
    }
    default:
      break;
  }
  return formatted;
};

export { formatMoney, DisplayMoneyFormat, parseMoney };
