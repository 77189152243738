const descriptions = {
  /**
   * Legacy plan.
   */
  PlootoPlanAServiceSubscription: 'Legacy',
  /**
   * Legacy plan.
   */
  PlootoPlanBServiceSubscription: 'Legacy',
  /**
   * Legacy plan.
   */
  PlootoPlanCServiceSubscription: 'Legacy',
  /**
   * Grow plan.
   */
  PlootoPlanDServiceSubscription: 'Automate payments with advanced workflows',
  /**
   * Grow Unlimited plan.
   */
  PlootoPlanEServiceSubscription: 'Automate payments and workflows with ease',
  /**
   * Go plan.
   */
  PlootoPlanFServiceSubscription: 'Begin automating payments',
} as const;

export default descriptions;
