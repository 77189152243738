import * as app from 'durandal/app';
import * as system from 'durandal/system';
import * as ko from 'knockout';

import { ApiService } from '@/features/api';
import { LegacyFeatureFlag } from '@/features/feature-flags/types/LegacyFeatureFlag';
import * as userService from '@/legacy/services/user';
import * as plootoUtils from '@/legacy/utils/plooto';
import * as viewModels from '@/legacy/viewmodels/viewModels';

const API_PREFIX = 'v1';
const PRIVATE_MESSAGES_ENDPOINT_URL = plootoUtils.IsFeatureEnabled(
  LegacyFeatureFlag.FEATURE_ENABLE_PRIVATE_MESSAGES_AZURE_FUNCTION
)
  ? import.meta.env.FEATURE_VERIFICATION_BASE_URL
  : `${import.meta.env.APP_URLS_API_URL}/${API_PREFIX}`;

export enum CompanyBusinessType {
  individual,
  solePproprietorship,
  partnership,
  corporation,
  privateCompany,
  publicCompany,
  nonprofit,
  governmentEntity,
}

export enum CardStatus {
  Loading = 0,
  Pending,
  UnderReview,
  AttentionRequired,
  Completed,
  PendingOther,
  PendingCapabilities,
}

export enum VerificationStatusEnum {
  PENDING,
  PROCESSING,
  REVIEWED,
  COMPLETED,
  INPUT_REQUIRED,
  UPLOAD_REQUIRED,
  PENDING_OTHER,
  PENDING_CAPABILITIES,
  IDENTITY_REVERIFICATION_REQUIRED,
}

export enum InputSubTypeEnum {
  FILE_INPUT = 0,
  TEXT_INPUT = 1,
  NONE = 2,
  CORPORATION_NUMBER_INPUT = 3,
  AUTHORIZER_REQUIRED = 4,
}

export enum VerificationType {
  Account = 0,
  Identity,
  Company,
}
export enum VerificationBarStep {
  Email,
  Financial,
  Personal,
  Company,
  Team,
}
export interface VerificationStatus {
  status: string;
  completed: boolean;
  error?: boolean;
  inputDescription?: string;
  id?: string;
  discriminator?: string;
}

export enum MessageDirection {
  ToCompliance,
  FromCompliance,
}

export class PrivateMessageBindingModel {
  companyId?: string;

  userId?: string;

  accountId?: string;

  parentId?: string;

  messageDirection?: string;

  messageDirectionValue?: string;

  messageBody?: string;

  verificationId?: string;

  discriminator?: string;

  sentOn: string;

  deleted: boolean;
}

export class PrivateMessageViewModel {
  companyId?: string;

  userId?: string;

  accountId?: string;

  parentId?: string;

  messageDirection?: string;

  messageDirectionValue?: string;

  messageBody?: string;

  verificationId?: string;

  discriminator?: string;

  sentOn: string;

  deleted: boolean;
}

export class CompanyUserVerificationViewModel {
  public identityVerificationNeedsUserAction: boolean;

  public isOnboardingCompleted: boolean;

  public companyDirectorStatus: string;

  public hasCompanyCapabilities: boolean;

  public bankAccountNeedsUserAction: boolean;
}

export class VerificationStatusViewModel {
  public id: string;

  public status: string;

  public discriminator: string;

  public inputDescription: string;

  public completed: boolean;

  public priorityNumber: number;

  public priority: boolean;

  public role: string;
}
export class VerificationStatusIdentityViewModel extends VerificationStatusViewModel {
  public email: string;

  public fullName: string;

  public userId: string;

  public companyDirectorStatus: string;

  public companyAccountingFirmClientUserId: string;

  public hasCreatedBankAccount: boolean;

  public isIdentitySubmitted: boolean;

  public isIdentityVerified: boolean;

  public personaInquiryId: string;

  public submittedByUserId: string;
}

export class PersonalInformationBindingModel {
  public firstName: string;

  public lastName: string;

  public phone: string;

  public birthYear: number;

  public birthMonth: number;

  public birthDay: number;
}

export class AddressInformationBindingModel {
  public address: string;

  public suite: string;

  public country: string;

  public state: string;

  public city: string;

  public zipCode: string;
}

export class CompanyBusinessDetailsBindingModel {
  public name: string;

  public operatingName: string;

  public businessUrl: string;

  public corporationType: string;

  public businessCategory: string;
}

export class CompanyBusinessCorporationNumberBindingModel {
  public corporationNumber: string;

  public corporationNumberProvince?: string;

  public corporationNumberType: string;
}

export type Status =
  | 'COMPLETED'
  | 'PENDING'
  | 'PROCESSING'
  | 'REVIEWED'
  | 'REJECTED'
  | 'INPUT_REQUIRED'
  | 'UPLOAD_REQUIRED'
  | 'PENDING_OTHER'
  | 'PENDING_CAPABILITIES'
  | 'IDENTITY_REVERIFICATION_REQUIRED'
  | null;

export interface OnboardingWizardStatusViewModel {
  companyVerificationCompleted: boolean;
  companyVerificationStatus: Status;
  identityVerificationCompleted: boolean;
  accountVerificationCompleted: boolean;
  directorVerificationCompleted: boolean;
  isAnyBankAccountValidated: boolean;
  isInterestedInAccountingSoftware: boolean;
  hasConnectedAccountingSoftware: boolean;
  hasCompletedOnboarding: boolean;
  companyHasLimitTiers: boolean;

  accountingSoftwareCompleted: boolean;
  addPaymentCompleted: boolean;
}

export class JumioIdentityVerficationResponse {
  public timestamp: string;

  public transactionReference: string;

  public redirectUrl: string;

  public error?: boolean;
}

export class JumioIdentityVerificationUpdateTransactionStatusBindingModel {
  public jumioIdentityTransactionStatus: JumioIdentityTransactionStatus;

  public transactionReference: string;

  public transactionErrorMessage: JumioIdentityVerificationSubmissionErrors;

  public transactionErrorCode?: number;
}

export class JumioIdentityVerficationEventData {
  authorizationToken: string;

  transactionReference: string;

  eventType: number;

  dateTime: Date;

  payload: Payload;

  customerInternalReference: string;
}

export interface PersonaUserVerficationBindingModel {
  userId: string;
  companyId: string;
  isCompanyOwner?: boolean;
}

export interface PersonaCompanyVerificationBindingModel {
  companyId: string;
  name: string;
  operatingName: string | null;
  corporationType: string;
  businessUrl: string | null;
  address: AddressInformationBindingModel;
}

export interface PersonaVerificationBindingModel {
  companyInformation?: PersonaCompanyVerificationBindingModel;
  userInformation: PersonaUserVerficationBindingModel;
}

export interface PersonaVerificationViewModel {
  userVerification: VerificationStatusViewModel;
  companyVerification: VerificationStatusViewModel;
}

export interface Metainfo {
  mode: string;
  code?: number;
  codeExternal?: number;
}

export interface Payload {
  value: string;
  metainfo: Metainfo;
}

export enum JumioIdentityTransactionStatus {
  INITIATED,
  USER_SUBMISSION_SUCCESSFUL,
  USER_SUBMISSION_FAILED,
  SUCCESSFUL,
  FAILED,
}

export enum JumioIdentityVerificationSubmissionErrors {
  ErrorOccuredOnServer,
  AuthorizationTokenMissingInvalidOrExpired,
  SessionExpiredAfterTheUserJourneyStarted,
  ErrorOccurredTransmittingImageToServer,
  ErrorOccurredDuringVerificationStep,
  UserHasNoNetworkConnection,
  UnexpectedErrorOccuredInTheClient,
  ProblemWhileCommunicatingWithServer,
  FileUploadNotEnabledOrCameraUnavailable,
  ThreeDimensionalLivenessFaceCaptureFailedAfterThreeAttempts,
  BrowserDoesNotSupportCamera,
  NoAcceptableSubmissionInThreeAttempts,
}

export enum JumioIdentityVerificationStatus {
  LOADED = 'loaded',
  SUCCESS = 'success',
  ERROR = 'error',
}

export enum OutstandingVerificationAction {
  None = 'None',
  CompanyUploadRequired = 'CompanyUploadRequired',
  ValidatePinCode = 'ValidatePinCode',
  AccountUploadRequired = 'AccountUploadRequired',
  UserIdentityUploadRequired = 'UserIdentityUploadRequired',
  DisplayAccountLockedModal = 'DisplayAccountLockedModal',
  DisplayPinFailedModal = 'DisplayPinFailedModal',
  UserIdentityOutstanding = 'UserIdentityOutstanding',
  PrivateMessageReply = 'PrivateMessageReply',
}

export interface CompanyOutstandingVerificationItemsViewModel {
  isAnyVerificationUnderReview: boolean;
  hasCompletedOnboarding: boolean;
  companyHasApprovedCapabilties: boolean;
  outstandingVerificationItems: Array<OutstandingVerificationItem>;
}
export interface OutstandingVerificationItem {
  discriminator: string;
  title: string;
  content: string;
  isTeamVerification: boolean;
  cardAction: OutstandingVerificationAction;
}
export interface UserOutstandingVerificationItem extends OutstandingVerificationItem {
  userId: string;
}
export interface BankAccountOutstandingVerificationItem extends OutstandingVerificationItem {
  accountId: string;
}
export interface BankAccountPinCreditFailedOutstandingVerificationItem
  extends BankAccountOutstandingVerificationItem {
  microDepositError: string | null;
}

export type PersonaVerificationResponse = {
  companyVerification: {
    completed: boolean;
    discriminator: 'CompanyVerification';
    id: string;
    inputDescription: unknown;
    inputSubType: unknown;
    priority: boolean;
    priorityNumber: unknown;
    status: Status;
  };
  userVerification: {
    completed: boolean;
    discriminator: 'PersonaIdentityVerification';
    id: string;
    inputDescription: unknown;
    inputSubType: unknown;
    priority: boolean;
    priorityNumber: unknown;
    status: Status;
  };
};

export class VerificationService {
  public verificationStatuses = ko.observableArray<VerificationStatus>([]);

  public verificationLoadingStatus = ko.observable<string>('loading');

  private userService: userService.UserService;

  public verificationRequired = ko.observable(false);

  private companyId = ko.observable('');

  private initialized = false;

  public IsVerificationRequired = ko.observable(false);

  private Initialize() {
    if (this.initialized) {
      return;
    }
    this.initialized = true;
    this.userService = userService.Instance;
  }

  public GetAllVerifyCompanyStatus(companyId): JQueryPromise<Array<VerificationStatus>> {
    return system
      .defer<Array<VerificationStatus>>((dfd) => {
        userService.Instance.AuthorizedGet(
          `${PRIVATE_MESSAGES_ENDPOINT_URL}/verifications/company/${companyId}`
        )
          .then((verificationInfo: any) => {
            if (plootoUtils.IsNullOrUndefined(verificationInfo) || verificationInfo.error) {
              dfd.reject(verificationInfo);
              return;
            }
            dfd.resolve(verificationInfo);
          })
          .fail((verificationInfo: VerificationStatus) => {
            dfd.reject(verificationInfo);
          });
      })
      .promise();
  }

  public GetCompanyUserVerificationStatus(
    companyId
  ): JQueryPromise<CompanyUserVerificationViewModel> {
    return system
      .defer<CompanyUserVerificationViewModel>((dfd) => {
        userService.Instance.AuthorizedGet(
          `${PRIVATE_MESSAGES_ENDPOINT_URL}/verifications/company/${companyId}/user`
        )
          .then((verificationInfo: any) => {
            if (plootoUtils.IsNullOrUndefined(verificationInfo) || verificationInfo.error) {
              dfd.reject(verificationInfo);
              return;
            }
            dfd.resolve(verificationInfo);
          })
          .fail((verificationInfo: VerificationStatus) => {
            dfd.reject(verificationInfo);
          });
      })
      .promise();
  }

  public CheckAllVerifyCompany(companyId) {
    this.Initialize();
    this.companyId(companyId);
    // reset account verification

    this.verificationLoadingStatus('loading');

    return this.GetAllVerifyCompanyStatus(companyId).done(
      (verificationStatuses: Array<VerificationStatus>) => {
        this.verificationStatuses(verificationStatuses);
        this.verificationLoadingStatus('loaded');
        let verificationRequired = false;
        let verificationCount = 0;
        const metaData = { CompanyId: companyId };

        const emailVerification = $.grep(
          verificationStatuses,
          (item) => item.discriminator === 'EmailVerification' && item.completed === false
        );

        $.each(verificationStatuses, (index, verificationStatus) => {
          if (verificationStatus.status != 'COMPLETED') {
            verificationCount += 1;
            verificationRequired = true;
          }
        });

        if (verificationCount > 1) {
          app.trigger('verificationItemsRequired', {});
        }

        this.IsVerificationRequired(verificationRequired);
      }
    );
  }

  public ReloadVerificationStatuses(companyId: string) {
    return this.CheckAllVerifyCompany(companyId);
  }

  public OnLoadedVerificationStatuses(callback: any = null): JQueryPromise<any> {
    let response = null;
    if (callback !== null) {
      response = callback();
    }
    return system
      .defer((dfd) => {
        if (this.verificationLoadingStatus() == 'loaded') {
          dfd.resolve(response);
        } else {
          const loaderSubscription: KnockoutSubscription = this.verificationLoadingStatus.subscribe(
            (newStatus) => {
              if (newStatus == 'loaded') {
                loaderSubscription.dispose();
                dfd.resolve(response);
              }
              if (newStatus == 'error') {
                loaderSubscription.dispose();
              }
            }
          );
        }
      })
      .promise();
  }

  public VerifyEmail(userId: string, emailAddress: string): JQueryPromise<VerificationStatus> {
    return system
      .defer<VerificationStatus>((dfd) => {
        userService.Instance.AuthorizedPost(
          `${import.meta.env.APP_URLS_API_URL}/${API_PREFIX}/users/${userId}/verification`,
          {
            emailAddress,
          }
        )
          .done((verificationInfo: VerificationStatus) => {
            if (plootoUtils.IsNullOrUndefined(verificationInfo) || verificationInfo.error) {
              dfd.reject(verificationInfo);
              return;
            }
            dfd.resolve(verificationInfo);
          })
          .fail((verificationInfo: VerificationStatus) => {
            dfd.reject(verificationInfo);
          });
      })
      .promise();
  }

  public VerifyAccount(accountId: string, formData: FormData): JQueryPromise<VerificationStatus> {
    return system
      .defer<VerificationStatus>((dfd) => {
        userService.Instance.AuthorizedUpload(
          `${import.meta.env.APP_URLS_API_URL}/${API_PREFIX}/verifications/accounts/${accountId}`,
          formData
        )
          .done((verificationInfo: VerificationStatus) => {
            if (plootoUtils.IsNullOrUndefined(verificationInfo) || verificationInfo.error) {
              dfd.reject(verificationInfo);
              return;
            }
            dfd.resolve(verificationInfo);
          })
          .fail((verificationInfo: VerificationStatus) => {
            dfd.reject(verificationInfo);
          });
      })
      .promise();
  }

  public UploadVerificationFile(
    verificationId: string,
    formData: FormData
  ): JQueryPromise<VerificationStatus> {
    return system
      .defer<VerificationStatus>((dfd) => {
        userService.Instance.AuthorizedUpload(
          `${
            import.meta.env.APP_URLS_API_URL
          }/${API_PREFIX}/verifications/${verificationId}/verificationFiles`,
          formData
        )
          .done((verificationInfo: VerificationStatus) => {
            if (plootoUtils.IsNullOrUndefined(verificationInfo) || verificationInfo.error) {
              dfd.reject(verificationInfo);
              return;
            }
            dfd.resolve(verificationInfo);
          })
          .fail((verificationInfo: VerificationStatus) => {
            dfd.reject(verificationInfo);
          });
      })
      .promise();
  }

  public GetVerifyAccountStatus(accountId): JQueryPromise<VerificationStatus> {
    return system
      .defer<VerificationStatus>((dfd) => {
        userService.Instance.AuthorizedGet(
          `${PRIVATE_MESSAGES_ENDPOINT_URL}/verifications/accounts/${accountId}/status`
        )
          .then((verificationInfo: VerificationStatus) => {
            if (plootoUtils.IsNullOrUndefined(verificationInfo) || verificationInfo.error) {
              dfd.reject(verificationInfo);
              return;
            }
            dfd.resolve(verificationInfo);
          })
          .fail((verificationInfo: VerificationStatus) => {
            dfd.reject(verificationInfo);
          });
      })
      .promise();
  }

  public getCompanyActivity(companyId): JQueryPromise<boolean> {
    return system
      .defer<boolean>((dfd) => {
        userService.Instance.AuthorizedGet(
          `${PRIVATE_MESSAGES_ENDPOINT_URL}/verifications/company/${companyId}/activity`
        )
          .then((verificationInfo: any) => {
            if (plootoUtils.IsNullOrUndefined(verificationInfo) || verificationInfo.error) {
              dfd.reject(verificationInfo);
              return;
            }
            dfd.resolve(verificationInfo);
          })
          .fail((verificationInfo: VerificationStatus) => {
            dfd.reject(verificationInfo);
          });
      })
      .promise();
  }

  public getVerificationStatusById(verificationId): JQueryPromise<VerificationStatus> {
    return system
      .defer<VerificationStatus>((dfd) => {
        userService.Instance.AuthorizedGet(
          `${PRIVATE_MESSAGES_ENDPOINT_URL}/verifications/${verificationId}/status`
        )
          .then((verificationStatus: any) => {
            if (plootoUtils.IsNullOrUndefined(verificationStatus) || verificationStatus.error) {
              dfd.reject(verificationStatus);
              return;
            }
            dfd.resolve(verificationStatus);
          })
          .fail((verificationStatus: VerificationStatus) => {
            dfd.reject(verificationStatus);
          });
      })
      .promise();
  }

  public getVerificationStatus(verificationBarStep: VerificationBarStep) {
    switch (verificationBarStep) {
      case VerificationBarStep.Email: {
        let currentVerificationStatus = 'PENDING';
        $.each(this.verificationStatuses(), (index, verificationStatus: VerificationStatus) => {
          if (
            verificationStatus.discriminator == 'EmailVerification' &&
            (verificationStatus as any).userId == userService.Instance.UserId()
          ) {
            currentVerificationStatus = verificationStatus.status;
          }
        });
        return currentVerificationStatus;
      }
      case VerificationBarStep.Financial: {
        let currentVerificationStatus = 'COMPLETED';

        $.each(this.verificationStatuses(), (index, verificationStatus: VerificationStatus) => {
          if (verificationStatus.discriminator == 'AccountVerification') {
            if (
              verificationStatus.status == 'PENDING' ||
              verificationStatus.status == 'UPLOAD_REQUIRED'
            ) {
              currentVerificationStatus = 'PENDING';
            }
            if (
              verificationStatus.status == 'PROCESSING' &&
              currentVerificationStatus != 'PENDING'
            ) {
              currentVerificationStatus = verificationStatus.status;
            }
          }
        });
        return currentVerificationStatus;
      }
      case VerificationBarStep.Personal: {
        let currentVerificationStatus = 'PENDING';
        $.each(this.verificationStatuses(), (index, verificationStatus: VerificationStatus) => {
          if (
            verificationStatus.discriminator == 'IdentityVerification' &&
            (verificationStatus as any).userId == userService.Instance.UserId()
          ) {
            currentVerificationStatus = verificationStatus.status;
          }
        });
        return currentVerificationStatus;
      }
      case VerificationBarStep.Company: {
        let currentVerificationStatus = 'PENDING';
        $.each(this.verificationStatuses(), (index, verificationStatus: VerificationStatus) => {
          if (verificationStatus.discriminator == 'CompanyVerification') {
            currentVerificationStatus = verificationStatus.status;
          }
        });
        return currentVerificationStatus;
      }
      case VerificationBarStep.Team: {
        let currentVerificationStatus = 'COMPLETED';
        $.each(this.verificationStatuses(), (index, verificationStatus: VerificationStatus) => {
          if (
            (verificationStatus.discriminator == 'IdentityVerification' ||
              verificationStatus.discriminator == 'EmailVerification') &&
            (verificationStatus as any).userId != userService.Instance.UserId()
          ) {
            if (
              verificationStatus.status == 'PENDING' ||
              verificationStatus.status == 'UPLOAD_REQUIRED'
            ) {
              currentVerificationStatus = verificationStatus.status;
            }
            if (
              verificationStatus.status == 'PROCESSING' &&
              currentVerificationStatus != 'PENDING'
            ) {
              currentVerificationStatus = verificationStatus.status;
            }
          }
        });
        return currentVerificationStatus;
      }
    }
    return null;
  }

  public getPrivateMessagesByVerificationStatusId(
    companyId: string,
    verificationId: string
  ): JQueryPromise<Array<PrivateMessageViewModel>> {
    return system
      .defer<any>((dfd) => {
        userService.Instance.AuthorizedGetFromUri(
          `${PRIVATE_MESSAGES_ENDPOINT_URL}/compliance/privateMessages/${companyId}/${verificationId}`,
          {
            filter: false,
          }
        )
          .then((privateMessages: Array<PrivateMessageViewModel>) => {
            if (plootoUtils.IsNullOrUndefined(privateMessages)) {
              dfd.reject(privateMessages);
              return;
            }
            dfd.resolve(privateMessages);
          })
          .fail((privateMessages: any) => {
            dfd.reject(privateMessages);
          });
      })
      .promise();
  }

  public sendPrivateMessageToCompliance(
    companyId: string,
    verificationId: string,
    privateMessageBindingModel: PrivateMessageBindingModel
  ): JQueryPromise<any> {
    return system
      .defer<any>((dfd) => {
        userService.Instance.AuthorizedPost(
          `${PRIVATE_MESSAGES_ENDPOINT_URL}/compliance/privateMessages/${companyId}/${verificationId}`,
          privateMessageBindingModel
        )
          .then((result: any) => {
            if (plootoUtils.IsNullOrUndefined(result) || result.error === true) {
              dfd.reject(result);
              return;
            }
            dfd.resolve(result);
          })
          .fail((privateMessages: Array<any>) => {
            dfd.reject(privateMessages);
          });
      })
      .promise();
  }

  public getPrivateMessagesByFilter(
    companyId: string,
    verificationId: string,
    privateMessagesFilterBindingModel: viewModels.PrivateMessagesFilterBindingModel
  ) {
    return system.defer<any>((dfd) => {
      userService.Instance.AuthorizedGetFromUri(
        `${PRIVATE_MESSAGES_ENDPOINT_URL}/compliance/privateMessages/${companyId}/${verificationId}`,
        {
          privateMessagesFilterBindingModel,
        }
      )
        .then((result) => {
          if (plootoUtils.IsNullOrUndefined(result) || result.error === true) {
            dfd.reject(result);
            return;
          }
          dfd.resolve(result);
        })
        .fail((error) => {
          dfd.reject(error);
        });
    });
  }

  public getVerificationFileStatusByVerificationId(companyId: string, verificationId: string) {
    return system.defer<any>((dfd) => {
      userService.Instance.AuthorizedGet(
        `${PRIVATE_MESSAGES_ENDPOINT_URL}/verifications/fileStatus/${companyId}/${verificationId}`
      )
        .then((result) => {
          if (plootoUtils.IsNullOrUndefined(result) || result.error === true) {
            dfd.reject(result);
            return;
          }
          dfd.resolve(result);
        })
        .fail((error) => {
          dfd.reject(error);
        });
    });
  }

  public getOnboardingWizardStatus(
    companyId: string
  ): JQueryPromise<OnboardingWizardStatusViewModel> {
    return system.defer<any>((dfd) => {
      userService.Instance.AuthorizedGet(
        `${import.meta.env.APP_URLS_API_URL}/${API_PREFIX}/companies/${companyId}/onboarding/status`
      )
        .then((result) => {
          if (plootoUtils.IsNullOrUndefined(result) || result.error === true) {
            dfd.reject(result);
            return;
          }

          dfd.resolve(result);
        })
        .fail((error) => {
          dfd.reject(error);
        });
    });
  }

  public getOutstandingVerificationItems(
    companyId: string
  ): JQueryPromise<CompanyOutstandingVerificationItemsViewModel> {
    return system.defer<any>((dfd) => {
      userService.Instance.AuthorizedGet(
        `${
          import.meta.env.APP_URLS_API_URL
        }/${API_PREFIX}/companies/${companyId}/verification/outstandingVerificationItems`
      )
        .then((result) => {
          if (plootoUtils.IsNullOrUndefined(result) || result.error === true) {
            dfd.reject(result);
            return;
          }

          dfd.resolve(result);
        })
        .fail((error) => {
          dfd.reject(error);
        });
    });
  }

  public postPersonaVerification(
    verificationBindingModel: PersonaVerificationBindingModel
  ): Promise<PersonaVerificationResponse> {
    return ApiService.post(
      `${import.meta.env.APP_URLS_API_URL}/${API_PREFIX}/verifications`,
      { body: verificationBindingModel },
      true
    );
  }
}

export const Instance = new VerificationService();
