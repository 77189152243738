/**
 * @todo This is a bug with linting TS.
 * @see {@link https://github.com/typescript-eslint/tslint-to-eslint-config/issues/856}
 */
enum EventName {
  AutomaticIdVerificationCancelled = 'Automatic ID Verification Cancelled',
  AutomaticIdVerificationInitiated = 'Automatic ID Verification Initiated',
  AutomaticIdVerificationSubmitted = 'Automatic ID Verification Submitted',
  CompanyVerificationInitiated = 'Company Verification - Initiated',
  CompanyBusinessBasicContinue = 'Company Verification - 1.1 Business Basic Continue',
  CompanyBusinessAddressContinue = 'Company Verification - 1.2 Business Address Continue',
  CompanyOperatingComplete = 'Company Verification - 1.3 Operating Complete',
  CompanyCreated = 'Company Created',

  IdentityVerificationInitiated = 'Identity Verification - Initiated',
  IdentityOnlineVerification = 'Identity Verification - Online Verification',
  IdentityPhotoIdVerification = 'Identity Verification - Photo ID Verification',
  IdentityBasicContinue = 'Identity Verification - 1.1 Identity Basic Continue',
  IdentityResidentialAddressContinue = 'Identity Verification - 1.2 Residential Address Continue',
  IdentityOnlineVerificationContinue = 'Identity Verification - 1.3 Online Verification Continue',

  BankVerificationInitiated = 'Bank Verification Initiated',
  BankInstantVerification = 'Bank Verification - Instant Verification',
  BankManualSubmit = 'Bank Verification - Manual - Submit',
  BankManualVerification = 'Bank Verification - Manual Verification',

  SetPasswordAndContinue = 'Set Password and Continue',
  UserSignedIn = 'User Signed In',
  UserSignedOut = 'User Signed Out',
  CancelSubscription = 'Cancel Subscription',

  BusinessTypeSelected = 'Business Type Selected',
  AccountingBusinessTypeSelected = 'accounting',
  SmbBusinessTypeSelected = 'smb',
  NfpCharityTypeSelected = 'nfpCharity',
  MultiEntityBusinessSelected = 'multiEntity',

  TwoFactorAuthenticationSuccess = 'TwoFactorAuthenticationSuccess',

  EmailPreferencesChanged = 'Email Preferences Changed',
}

/**
 * @todo This is a bug with linting TS.
 * @see {@link https://github.com/typescript-eslint/tslint-to-eslint-config/issues/856}
 */
enum FormNameConstant {
  AddClient = 'Add Client',
  CreditCardKyc = 'New Credit Card Application Form',
  SetPassword = 'Set Password',
  TransferFundsForm = 'Transfer Funds',
}

/**
 * @todo This is a bug with linting TS.
 * @see {@link https://github.com/typescript-eslint/tslint-to-eslint-config/issues/856}
 */

/**
 * These are common fieldName constants used with FormSubmitted event type
 */
enum FormFieldNameConstants {
  SubmissionStatus = 'submissionStatus',
}

/**
 * @todo This is a bug with linting TS.
 * @see {@link https://github.com/typescript-eslint/tslint-to-eslint-config/issues/856}
 */

/**
 * These are common fieldValue constants used with FormSubmitted event type
 */
enum FormFieldValueConstants {
  Completed = 'completed',
  Incomplete = 'incomplete',
}

/**
 * @todo This is a bug with linting TS.
 * @see {@link https://github.com/typescript-eslint/tslint-to-eslint-config/issues/856}
 */
enum FormEventType {
  ButtonClicked = 'Button Clicked',
  FormFieldCaptured = 'Form Field Captured',
  FormPresented = 'Form Presented',
  FormSubmitted = 'Form Submitted',
  FormErrorReceived = 'Form Error Received',
}

interface UserTraits {
  email: string;
}

enum SignoutContext {
  Settings = 'settings signout',
  BusinessProfile = 'business profile signout',
  Jtbd = 'jtbd signout',
  Cashflow = 'cashflow signout',
  BusinessType = 'business type signout',
}

enum DialogEventType {
  DialogPresented = 'Dialog Presented',
  DialogDismissed = 'Dialog Dismissed',
  DialogAcknowledged = 'Dialog Acknowledged',
}

export type { UserTraits };
export {
  DialogEventType,
  EventName,
  FormEventType,
  FormNameConstant,
  FormFieldNameConstants,
  FormFieldValueConstants,
  SignoutContext,
};
