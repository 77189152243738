// Since this is a .js file, this code (which is a copy of the code inside main.ts) exists here
//   to accommodate path (pageId) injection when doing segment track and page calls.
function getAnalyticsLocationPageId() {
  let pageId = window.location.hash;

  try {
    if (pageId.length > 1 && pageId.substr(0, 1) === '#') pageId = pageId.substr(1);
  } catch (error) {
    return '';
  }
  // anonymize URL
  pageId = pageId.replace(/[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12}/gi, '<id>');
  pageId = pageId.replace(/[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,63}/gi, '<email>');
  // additional url stripping for openid related urls
  pageId = pageId.replace(
    /(openid\.intuit\.com%252FIdentity-)([0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{12})(\/)([0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{12})/gi,
    '$1<id>$3<id>'
  );

  return pageId;
}

export function identify(userId, traits) {
  try {
    window.analytics.identify(userId, traits);
  } catch (exception) {
    // placeholder
  }
}

export function track(eventType, eventValue) {
  try {
    window.analytics.track(eventType, {
      ...eventValue,
      path: getAnalyticsLocationPageId(),
    });
  } catch (exception) {
    // placeholder
  }
}

export function page(eventType, eventValue) {
  try {
    window.analytics.page(eventType, {
      ...eventValue,
      path: getAnalyticsLocationPageId(),
    });
  } catch (exception) {
    // placeholder
  }
}

export function reset() {
  try {
    window.analytics.reset();
  } catch (exception) {
    // placeholder
  }
}
